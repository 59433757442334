import config from './../config'
import axios from 'axios'
import { getToken, hasToken, setToken } from './jwt'

const headers = () => {
  // return { headers: hasToken() ? { Authorization: getToken() } : {} }
  return {}
}

const handleSuccess = response => {
  if (!response || !response.data || response.code > 300) {
    throw new Error('Wrong HTTP response')
  }

  const htmlResp = `${response.data}`

  // if (!response.data.data) {
  //   throw new Error('Wrong API response')
  // }

  return htmlResp
}

const handleFail = res => {
  let error = { message: 'API error' }

  if (res instanceof Error) {
    error =
      res.response && res.response.data && res.response.data.error ? res.response.data.error : { message: res.message }
  }

  if (res && res.error) {
    error = res.error
  }

  if (error && (error.code === 'ERROR_AUTHORIZATION' || error.code === 'ERROR_JWT')) {
    setToken(null)
  }

  // log error (to sentry, for example)

  throw error
}

export const normalizeParams = (params) => {
  if (params && typeof params === 'object') {
    if (params instanceof FormData) {
      return params;
    }
    if (params.toString() !== '[object Object]') {
      return params.toString();
    }
    const results = [];
    Object.keys(params).forEach(parentIndex => {
      if (params[parentIndex] instanceof Object) {
        Object.keys(params[parentIndex]).forEach(subIndex => {
          results.push(
            // eslint-disable-next-line max-len
            `${encodeURIComponent(parentIndex)}[${encodeURIComponent(subIndex)}]=${encodeURIComponent(params[parentIndex][subIndex])}`
          );
        });
      } else {
        results.push(
          `${encodeURIComponent(parentIndex)}=${encodeURIComponent(params[parentIndex])}`
        );
      }
    });
    return results.join('&');
  }

  if (params && typeof params === 'function') {
    return params();
  }

  return params.toString();
}

export const getRequest = (url, additionalHeaders = {}) => {
  const headersObj = headers()
  headersObj.headers = Object.assign(headersObj.headers ? headersObj.headers : {}, additionalHeaders)

  return axios
    .get(`${config.serverUrl}/${url}`, headersObj)
    .then(handleSuccess)
    .catch(handleFail)
}

export const postRequest = (url, data) => {
  return axios
    .post(`${config.serverUrl}/${url}`, data, headers())
    .then(handleSuccess)
    .catch(handleFail)
}

export const deleteRequest = url => {
  return axios
    .delete(`${config.serverUrl}/${url}`, headers())
    .then(handleSuccess)
    .catch(handleFail)
}

export const putRequest = (url, data) => {
  return axios
    .put(`${config.serverUrl}/${url}`, data, headers())
    .then(handleSuccess)
    .catch(handleFail)
}
