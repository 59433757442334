import { FORM_ERROR } from 'final-form'

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const ERROR_VALIDATION_CODE = 'ERROR_VALIDATION'

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const required = value => (value ? undefined : 'Required')
const phoneNumber = value => (isNaN(+`${value}`.replace(/[^0-9]*/g, '')) ? 'Please enter your phone number' : undefined)
const minAmount = min => value =>
  !isNaN(+`${value}`.replace(/[^0-9.]*/g, '')) && +`${value}`.replace(/[^0-9.]*/g, '') >= min
    ? undefined
    : `Minimum amount is ${min}`
const emailValidator = value => (EMAIL_REGEX.test(value) ? undefined : 'Please enter your email address')

const parseServerErrors = error => {
  if (error && error.code === ERROR_VALIDATION_CODE && error.message && typeof error.message === 'object') {
    return Object.keys(error.message).reduce((res, fieldName) => {
      const errKey = fieldName.charAt(0).toLowerCase() + fieldName.substring(1)
      if (!res[errKey]) {
        res[errKey] = error.message[fieldName]
        if (typeof res[errKey] === 'object') {
          res[errKey] = Object.keys(res[errKey]).reduce((result, key) => `${result}, ${res[errKey][key]}`, '')
        }
      }
      return res
    }, {})
  }

  if (error && error.code && typeof error.message === 'string') {
    return { [FORM_ERROR]: error.message }
  }

  return {}
}

export {
  EMAIL_REGEX,
  ERROR_VALIDATION_CODE,
  composeValidators,
  emailValidator,
  minAmount,
  parseServerErrors,
  phoneNumber,
  required,
}
