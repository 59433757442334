import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  margin-bottom: ${props => (props.enableSlimWrapper ? '0.75rem' : '2rem')};
  ${props =>
    props.teal &&
    css`
      label {
        color: ${props => props.theme.colors.white};
        font-size: ${props => props.theme.type.sizes.hardSizes.base};
        padding-bottom: 5px;
      }
    `}

`
const InputWrapper = ({ children, enableSlimWrapper = false, className, teal = false }) => (
  <Wrapper className={className} enableSlimWrapper={enableSlimWrapper} teal={teal}>
    {children}
  </Wrapper>
)

export default InputWrapper
