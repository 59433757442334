import React from 'react'
import styled from 'styled-components'

import iconSuccessGreenTick from '../../images/icon-success-green-tick.svg'

const Img = styled.img`
  width: 226px;
  height: 226px;
  margin: 1rem auto 2rem;
`

const Confirmation = styled(({ dark, ...props }) => <div {...props} />)`
  text-align: center;
  padding: 10px 0 80px 0;
  color: ${props => props.dark ? props.theme.colors.white : props.theme.colors.navy};
  
  h2 {
    color: ${props => props.dark ? props.theme.colors.white : props.theme.colors.navy};
    font-size: ${props => props.theme.type.sizes.medium};
    margin-bottom: 1rem;
  }
  & > div {
    max-width: 400px;
    margin: 0 auto;
  }
`

const FormConfirmation = ({ children, dark = false }) => (
  <Confirmation dark={dark}>
    <Img src={iconSuccessGreenTick} alt="icon success green tick" />
    {children}
  </Confirmation>
)

export default FormConfirmation
