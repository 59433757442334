import React from 'react'

import FormContact from '../components/FormContact'
import Layout from '../components/Layout'
import MenuSub, { MODE_LINKS, menuContact } from '../components/MenuSub'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import Section from '../components/Section'
import SlimTitle from '../components/SlimTitle'

const Contact = () => {
  const seoNode = {
    title: 'Zoopay - Contact Us',
    description:
      'Get in touch with the Zoopay team today on +44 207 740 0001.',
  }

  return (
    <Layout>
      <SEOPageTitle title={seoNode.title} />
      <SEO postNode={seoNode} pagePath="contact" customTitle customDescription />
      <MenuSub mode={MODE_LINKS} data={menuContact} />
      <Section lowLineHeight={true} borderBottom={false}>
        <SlimTitle>Contact Us<span>.</span></SlimTitle>
        <div className="row">
          <div className="col-md-6 col-12">
            <p>Please fill out the form to the right to get in contact with one of our members of staff, or use the below information to contact us.</p>
            <h4>Telephone</h4>
            <p>+44 207 740 0001</p>
            <h4>Email</h4>
            <p><a href={'mailto:contact@zoopay.com'}>contact@zoopay.com</a></p>
            <h4>Address</h4>
            <p>Unit 35 Hobbs Court, 2 Jacob Street,<br />
              London SE1 2BG</p>
          </div>
          <div className="col-md-6 col-12">
            <FormContact />
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default Contact
