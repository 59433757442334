import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  display: block;
  color: ${props => props.theme.colors.navy};
  font-size: ${props => props.theme.type.sizes.base};
  margin-bottom: 0.25rem;
`

const InputLabel = ({ className, htmlFor, children, as }) => (
  <Label as={as} className={className} htmlFor={htmlFor}>
    {children}
  </Label>
)

export default InputLabel
