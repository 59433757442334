import React from 'react'
import styled, { css } from 'styled-components'

import InputErrorMessage from '../InputErrorMessage'
import InputLabel from '../InputLabel'
import InputWrapper from '../InputWrapper'

const Input = styled.input`
  &[type='email'],
  &[type='number'],
  &[type='password'],
  &[type='text'] {
    box-sizing : border-box;
    background-clip: padding-box;
    background-color: ${props => props.theme.colors.white};
    border-radius: 0;
    border: 1px solid ${props => props.theme.colors.navy};
    caret-color: ${props => props.theme.colors.navy};
    color: ${props => props.theme.colors.navy};
    display: block;
    font-size: ${props => props.theme.type.sizes.base};
    height: 64px;
    line-height: 1.25;
    padding: 0.75rem 0.75rem;
    transition: ${props => props.theme.animations.transition};

    :disabled {
      color: ${props => props.theme.colors.grey};
      border: 1px solid ${props => props.theme.colors.grey};
      background-color: ${props => props.theme.colors.greyLightHover};
    }

    :focus {
      border: 1px solid ${props => props.theme.colors.navy};
      box-shadow: none;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${props => props.theme.colors.grey};
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${props => props.theme.colors.grey};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${props => props.theme.colors.grey};
    }

    ${props =>
    props.teal &&
    css`
      background-color: ${props => props.theme.colors.tealBackground};
      caret-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.white};
      :focus {
        border: 1px solid ${props => props.theme.colors.white};
      }
    `}

    ${props =>
      props.characterWidth === 2 &&
      css`
        max-width: 6.9ex;
      `}

    ${props =>
      props.characterWidth === 3 &&
      css`
        max-width: 8.8ex;
      `}

    ${props =>
      props.characterWidth === 4 &&
      css`
        max-width: 10.8ex;
      `}

    ${props =>
      props.characterWidth === 5 &&
      css`
        max-width: 11.5ex;
      `}

    ${props =>
      props.characterWidth === 7 &&
      css`
        max-width: 16.1ex;
      `}

    ${props =>
      props.characterWidth === 8 &&
      css`
        max-width: 18.4ex;

        @media (min-width: ${props => props.theme.responsive.large}) {
          max-width: 20.7ex;
        }
      `}

    ${props =>
      props.characterWidth === 9 &&
      css`
        max-width: 20.7ex;
      `}

    ${props =>
      props.characterWidth === 10 &&
      css`
        max-width: 23ex;
      `}

    ${props =>
      props.characterWidth === 15 &&
      css`
        max-width: 34.5ex;
      `}

    ${props =>
      props.characterWidth === 20 &&
      css`
        max-width: 46ex;
      `}

      ${props =>
        !props.characterWidth &&
        css`
          width: 100%;
        `}

    ${props =>
      props.error &&
      css`
        border: 1px solid ${props => props.theme.colors.red};
        color: ${props => props.theme.colors.red};
      `}
  }
`

const InputTextfield = ({
  autoComplete = 'on',
  characterWidth,
  disabled,
  enableSlimWrapper,
  id,
  input,
  inputmode,
  label,
  meta,
  teal,
  onChange,
  placeholder,
  pattern,
}) => {
  const hasError = (meta.error || meta.submitError) && meta.touched

  return (
    <InputWrapper enableSlimWrapper={enableSlimWrapper} teal={teal}>
      {label && <InputLabel htmlFor={id} teal={teal}>{label}</InputLabel>}
      <Input
        autoComplete={autoComplete}
        characterWidth={characterWidth}
        disabled={disabled}
        error={hasError}
        id={id}
        inputmode={inputmode}
        onChange={onChange}
        placeholder={placeholder}
        pattern={pattern}
        teal={teal}
        {...input}
      />
      {hasError && <InputErrorMessage>{meta.error || meta.submitError}</InputErrorMessage>}
    </InputWrapper>
  )
}

export default InputTextfield
