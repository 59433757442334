import jwtDecode from 'jwt-decode'

// use local storage by default
// eslint-disable-next-line no-undef
let storage = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined' ? window.localStorage : null

export const TOKEN_SESSION_KEY = 'authJwt'

// handler to switch to session storage
export const useSessionStorage = () => {
  storage = typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined' ? window.sessionStorage : null
}

export const hasToken = () => {
  const token = storage && storage.getItem(TOKEN_SESSION_KEY)
  return token && token.length > 16
}

export const isTokenExpired = () => {
  if (!hasToken()) {
    return true
  }

  const payload = jwtDecode(getToken())
  const time = new Date().getTime()
  const jwtExpireTime = payload.exp * 1000
  return jwtExpireTime < time
}

export const getToken = () => {
  return storage && storage.getItem(TOKEN_SESSION_KEY)
}

export const setToken = token => {
  if (!token) {
    storage && storage.removeItem(TOKEN_SESSION_KEY)
    return
  }

  storage && storage.setItem(TOKEN_SESSION_KEY, token)
}
