import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 0 0 140px 0;

  @media (max-width: ${props => props.theme.responsive.large}) {
    padding: 0 0 30px 0;
  }
`
