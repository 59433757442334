import React, { Component } from 'react'
import { Form } from 'react-final-form'

import FormComponent from './form'
import { postRequest, normalizeParams } from './../../utils/requests'
import { parseServerErrors } from './../../utils/validation'

class FormContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      successMessage: '',
    }
  }

  onSubmit = async values => {
    try {
      const response = await postRequest('contact-us', normalizeParams({...values}))

      if (response) {
        this.setState({ successMessage: 'Your message was sent successfully. A member of our team will contact you shortly.' })
      }
    } catch (error) {
      return parseServerErrors(error)
    }
  }

  render() {
    const { successMessage } = this.state
    const { focusedReason } = this.props
    return (
      <Form
        onSubmit={this.onSubmit}
        render={FormComponent}
        focusedReason={focusedReason}
        initialValues={{}}
        successMessage={successMessage}
        validateOnBlur
      />
    )
  }
}

export default FormContact
