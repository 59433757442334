import React from 'react'
import styled, { css } from 'styled-components'

import InputErrorMessage from '../InputErrorMessage'
import InputLabel from '../InputLabel'
import InputWrapper from '../InputWrapper'

const Textarea = styled.textarea`
  background-clip: padding-box;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0;
  border: 1px solid ${props => props.theme.colors.navy};
  caret-color: ${props => props.theme.colors.navy};
  color: ${props => props.theme.colors.navy};
  display: block;
  font-size: ${props => props.theme.type.sizes.base};
  line-height: 1.25;
  max-width: 566px;
  padding: 0.75rem 0.75rem;
  transition: ${props => props.theme.animations.transition};
  width: 100%;

  :disabled {
    color: ${props => props.theme.colors.grey};
    border: 1px solid ${props => props.theme.colors.grey};
    background-color: ${props => props.theme.colors.greyLightHover};
  }

  :focus {
    border: 1px solid ${props => props.theme.colors.navy};
    box-shadow: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.colors.grey};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.theme.colors.grey};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.theme.colors.grey};
  }

  ${props =>
    props.error &&
    css`
      border: 1px solid ${props => props.theme.colors.red};
      color: ${props => props.theme.colors.red};
    `}
`

const InputTextarea = ({
  autoComplete = 'off',
  characterWidth,
  disabled,
  enableSlimWrapper,
  id,
  input,
  label,
  meta,
  onChange,
  placeholder,
}) => {
  const hasError = (meta.error || meta.submitError) && meta.touched

  return (
    <InputWrapper enableSlimWrapper={enableSlimWrapper}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <Textarea
        rows={6}
        autoComplete={autoComplete}
        characterWidth={characterWidth}
        disabled={disabled}
        error={hasError}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        {...input}
      />
      {hasError && <InputErrorMessage>{meta.error || meta.submitError}</InputErrorMessage>}
    </InputWrapper>
  )
}

export default InputTextarea
