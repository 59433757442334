import React from 'react'
import styled from 'styled-components'

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.type.sizes.small};
  font-weight: ${props => props.theme.type.weights.bold};
  margin-top: 5px;

  ::before {
    border-radius: 8px;
    border: 2px solid ${props => props.theme.colors.redHover};
    content: '!';
    display: inline-block;
    font-size: ${props => props.theme.type.sizes.tiny};
    font-weight: ${props => props.theme.type.weights.bold};
    height: 16px;
    margin-right: 8px;
    text-align: center;
    transition: ${props => props.theme.animations.transition};
    width: 16px;
  }
`

const InputErrorMessage = ({ children }) => <ErrorMessage>{children}</ErrorMessage>

export default InputErrorMessage
